<script>
import TheButton from "@/components/Button/Button.vue";
import ArrowDown from "@/assets/icons/ArrowDown.vue";

export default {
  name: "StudentsWork",
  components: { ArrowDown, TheButton },
  data() {
    return {
      activeButton: "Все направления",
      showAll: false,
      totalItems: 16,
      visibleItems: 4,
    };
  },
  computed: {
    displayedItems() {
      return this.showAll ? this.totalItems : this.visibleItems;
    },
  },
  methods: {
    setActiveButton(button) {
      this.activeButton = button;
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
  },
};
</script>

<template>
  <div class="students-work">
    <div class="students-work__main">
      <div class="students-work__nav">
        <h1 class="students-work__title">Работы наших учеников</h1>
        <div class="students-work__btns">
          <TheButton
              class="students-work__btn"
              :class="{ active: activeButton === 'Все направления' }"
              @click="setActiveButton('Все направления')"
          >
            Все направления
          </TheButton>
          <TheButton
              class="students-work__btn"
              :class="{ active: activeButton === 'Веб разработка' }"
              @click="setActiveButton('Веб разработка')"
          >
            Веб разработка
          </TheButton>
          <TheButton
              class="students-work__btn"
              :class="{ active: activeButton === 'Графический и UI/UX дизайн' }"
              @click="setActiveButton('Графический и UI/UX дизайн')"
          >
            Графический и UI/UX дизайн
          </TheButton>
        </div>
      </div>
      <div class="students-work__content">
        <div class="students-work__list">
          <div class="students-work__item" v-for="index in displayedItems" :key="index">
            <p>тут будет обложка</p>
          </div>
        </div>
        <TheButton class="students-work__outline-btn" @click="toggleShowAll">
          {{ showAll ? 'Скрыть' : 'Показать еще' }}
          <ArrowDown  v-if="!showAll" />
        </TheButton>
      </div>
    </div>
  </div>
</template>


<style scoped>

.students-work__main{
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}

.students-work__title{
  font-size: 40px;
  font-weight: 800;
  margin-top: 40px;
  margin-bottom: 32px;
  color: #1F2937;
}

.students-work__btns {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.students-work__btn {
  color: #000000;
  padding: 6px 16px;
  background-color: #FFFFFF;
  border-color: #786ce6b2;
  transition: all 0.3s ease;
}
.students-work__btn.active {
  background-color: #786CE6;
  color: #FFFFFF;
}

.students-work__list {
  margin: 80px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
}



.students-work__item {
  width: 292px;
  height: 280px;
  background: #2B2E3B;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.students-work__item p {
  color: #FFFFFF;
}

.students-work__outline-btn {
  font-weight: 500;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 16px 24px;
  background: none;
  margin-bottom: 40px;
  border-radius: 32px;
  border: 2px solid #6759EA;
  color: #6759EA;
}

.students-work__content {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
}


@media (max-width: 1250px) {
  .students-work__main {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .students-work__title {
    font-size: 30px;
  }
}

</style>