const baseUrl = "/api/v2/courses/course_groups";
export default ($axios) => ({
  get_course_group_users(params) {
    return $axios.get(`${baseUrl}/${params.group_number}/users/`);
  },
  get_course_group_points(params) {
    return $axios.get(
      `${baseUrl}/${params.group_number}/points/?task_category_ids=${params.task_category_ids}&total_lte=${params.total_lte}&user_search=${params.user}`,
      params
    );
  },
  create_course_group_points_many(params) {
    return $axios.post(
      `${baseUrl}/${params.group_number}/create_or_update_many/`,
      params
    );
  },
  get_my_course_group_points(params) {
    return $axios.get(`${baseUrl}/${params.group_number}/my_points/`);
  },
  get_course_group_points_statistics(params) {
    return $axios.get(`${baseUrl}/${params.group_number}/points/statistics/`);
  },
  download_grade_records(params) {
    return $axios({
      url: `${baseUrl}/${params.course}/points_to_excel/?task_category_ids=${params.task_category_ids}&total_lte=${params.total_lte}&user_search=${params.user}`,
      method: "GET",
      responseType: "blob",
    });
  },
});
