<template>
  <div
      class="fixed mt-14 w-64 flex-col inset-y-0 z-40 border-gray-600 border border-2 shadow-sm"
      :class="is_sidebar_open ? 'flex' : 'hidden md:flex'"
      ref="sidebar"
  >
    <div
        class="absolute top-0 right-0 -mr-12 pt-2"
        :class="is_sidebar_open ? 'block' : 'hidden'"
    >
      <button
          type="button"
          class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          @click.stop="toggleSidebar"
      >
        <span class="sr-only">Close sidebar</span>
        <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
      </button>
    </div>
    <div class="flex flex-col flex-grow pt-5 bg-white overflow-y-auto">
      <div class="mt-5 flex-grow flex flex-col ml-4">
        <nav class="flex-1 px-2 pb-4 space-y-1" @click="closeSidebar">
          <slot />
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  mounted() {
    document.addEventListener("mousedown", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  },
  computed: {
    ...mapFields(["core.is_sidebar_open"]),
  },
  methods: {
    toggleSidebar() {
      this.is_sidebar_open = !this.is_sidebar_open;
    },
    closeSidebar() {
      this.is_sidebar_open = false;
    },
    handleClickOutside(event) {
      if (this.is_sidebar_open && this.$refs.sidebar && !this.$refs.sidebar.contains(event.target)) {
        this.closeSidebar();
      }
    },
  },
};
</script>
