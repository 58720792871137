<script>
import { defineComponent } from "vue";
import TheTable from "@/components/TheTable/TheTable.vue";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";
import TheTableRow from "@/components/TheTable/TheTableRow.vue";
import TheTableBody from "@/components/TheTable/TheTableBody.vue";
import TheTableHead from "@/components/TheTable/TheTableHead.vue";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
import MyGradesFetchMixin from "@/views/courses/MyGrades/MyGradesFetchMixin";
import gr from "vue-tailwind/dist/l10n/gr";

export default defineComponent({
  name: "MyGrades",
  computed: {
    gr() {
      return gr;
    },
  },
  components: {
    TheTableHeadCell,
    TheTableHead,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
    TheTable,
  },
  mixins: [MyGradesFetchMixin],
});
</script>

<template>
  <div class="px-8 pt-7 flex flex-col gap-6">
    <h1>Мои оценки</h1>
    <div class="my-grades__div">
      <div>
        <TheTable class="mt-8 mb-2 table-fixed w-full">
        <TheTableHead>
            <TheTableHeadCell
                v-for="(grade, index) in grade_categories"
                :key="index"
            >
              <div class="flex flex-col px-2 py-1">
                <div
                    class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  {{ grade.title }}
                </div>
              </div>
            </TheTableHeadCell>

          <TheTableHeadCell :colspan="grade_categories.length">
            <div class="flex flex-col px-2 py-1">
              <div class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500">
                Total
              </div>
              <div class="text-xs font-medium tracking-wider text-gray-500">
                из 100
              </div>
            </div>
          </TheTableHeadCell>

        </TheTableHead>
          <TheTableBody>
            <TheTableRow>
              <template v-for="([key, grade], index) in Object.entries(grades)" :key="key">
                <TheTableRowCell v-if="index < Object.keys(grades).length - 1">
                  <div class="text-sm leading-5 font-normal px-2 py-1">
                    <span class="text-sm leading-5 font-medium text-gray-900">
                      {{ grade.grade!==null ? grade.grade : 0 }}
                    </span>
                  </div>
                </TheTableRowCell>
              </template>

              <TheTableRowCell>
                <div class="text-sm leading-5 font-normal px-2 py-1">
                  <span class="text-sm leading-5 font-medium text-gray-900">
                    {{Number(total_grades.total_score).toFixed(2)}}
                  </span>
                </div>
              </TheTableRowCell>
            </TheTableRow>
          </TheTableBody>
        </TheTable>
      </div>
    </div>
  </div>
</template>
