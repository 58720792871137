export default [
  {
    name: "TheLandingMain",
    path: "",
    meta: {
      isPublic: true,
      excludeLayout: true,
    },

    component: async () => require("../views/core/Landing/TheLandingMain"),
    children: [
      {
        name: "TheLandingPage",
        path: "",
        component: async () => require("../views/core/Landing/TheLandingPage"),
      },
      {
        name: "Check Certificates",
        path: "check-certificates",
        component: async () =>
          require("../views/certificates/CheckCertificates.vue"),
      },
      {
        name: "AllCourses",
        path: "all-courses",
        component: async () =>
          require("../views/core/Landing/Courses/AllCourses/TheLandingAllCourses.vue"),
      },
      {
        name: "Tariffs",
        path: "tariffs",
        component: async () =>
          require("../views/core/Landing/Tariffs/TheLandingTariffs.vue"),
      },
      {
        name: "About Us",
        path: "about-us",
        component: async () =>
          require("../views/core/Landing/AboutUs/AboutUsPage.vue"),
      },
      {
        name: "All Guest Lectures",
        path: "guest-lectures",
        component: async () =>
            require("../views/core/Landing/GuestLectures/AllGuestLectures.vue"),
      },
      {
        name: "Students Work",
        path: "students-work",
        component: async () =>
            require("../views/core/Landing/StudentsWork/StudentsWork.vue"),
      },
      {
        name: "Services",
        path: "services",
        component: async () =>
          require("../views/core/Landing/Services/ServicesPage.vue"),
      },
      {
        name: "ShoppingCart",
        path: "shopping-cart",
        component: async () =>
          require("../views/core/Landing/Courses/ShoppingCart/ShoppingCart.vue"),
      },
      {
        name: "AllTeachers",
        path: "all-teachers",
        component: async () =>
          require("../views/core/Landing/Courses/AllTeachers/TheLandingAllTeachers.vue"),
      },
      {
        name: "AllCoursesFilters",
        path: "all-courses/filters",

        component: async () =>
          require("../views/core/Landing/Courses/TheLandingFilters.vue"),
      },
      {
        name: "CoursePageWeb",
        path: "all-courses/web",
        component: async () =>
          require("../views/core/Landing/Courses/TheLandingCourse"),
      },
      {
        name: "CoursePageDesigner",
        path: "all-courses/design",
        component: async () =>
          require("../views/core/Landing/Courses/TheLandingCourseDesigner"),
      },
      {
        name: "CoursePageMobileDev",
        path: "all-courses/mob-dev",
        component: async () =>
          require("../views/core/Landing/Courses/TheLandingCourseMobDev"),
      },
      {
        name: "CoursePageGameDev",
        path: "all-courses/game-dev",
        component: async () =>
          require("../views/core/Landing/Courses/TheLandingCourseGameDev"),
      },
      {
        name: "CoursePagePython",
        path: "all-courses/programming",
        component: async () =>
          require("../views/core/Landing/Courses/TheLandingCoursePython.vue"),
      },
      {
        name: "CoursePagePythonAI",
        path: "all-courses/programming-ai",
        component: async () =>
          require("../views/core/Landing/Courses/TheLandingCoursePythonAI.vue"),
      },
      {
        name: "CoursePageGenAI",
        path: "all-courses/gen-ai",
        component: async () =>
          require("../views/core/Landing/Courses/TheLandingCourseGenAI.vue"),
      },
      {
        name: "AllStudentStories",
        path: "student-stories",
        component: async () =>
          require("../views/core/Landing/Courses/StudentFeedbacks/AllStudentStories.vue"),
      },
      {
        name: "AllStudentWorks",
        path: "student-works",
        component: async () =>
          require("../views/core/Landing/Courses/StudentWorks/AllStudentWorks.vue"),
      },
    ],
  },

  {
    name: "Course Catalog",
    path: "/catalog",
    component: async () => require("@/views/core/Landing/CoursesCatalog"),
  },

  {
    name: "Info Page",
    path: "/info",
    component: async () => require("@/views/core/Landing/InfoPage"),
  },
  {
    name: "Course Overview",
    path: "/course/:id",
    component: async () => require("@/views/core/Landing/CourseOverview"),
  },
  {
    name: "News List",
    path: "/news",
    component: async () => require("@/views/core/Landing/NewsList"),
  },
  {
    name: "News Item",
    path: "/news/:id",
    component: async () => require("@/views/core/Landing/NewsItem"),
  },
  {
    name: "Notifications",
    path: "",
    component: async () => require("../layouts/CabinetLayout"),
    children: [
      {
        name: "NotificationList",
        path: "/notifications",
        component: async () =>
          require("../views/core/Notifications/NotificationList.vue"),
      },
    ],
  },
  {
    name: "NotificationLayout",
    path: "",
    component: async () => require("../layouts/NotificationLayout"),
    children: [
      {
        name: "Notification",
        path: "/notifications/:id",
        component: async () =>
          require("../views/core/Notifications/NotificationView.vue"),
      },
    ],
  },
  // {
  //     name:"FAQs",
  //     path:'/faq',
  //     component:async ()=>require('../layouts/CabinetLayout'),
  //     children: [
  //         {
  //             name:"FAQ",
  //             path:'',
  //             component:async ()=>require("../views/core/FAQ/TheFaqPage")
  //         }
  //     ]
  // }
];
