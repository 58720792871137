import { mapActions } from "vuex";
import { GET_COURSE_GROUP_POINTS_STATISTICS } from "@/store/student-grades";

export default {
  data() {
    return {
      statistics: null,
    };
  },
  async mounted() {
    await this.getGradesStatistics();
  },
  methods: {
    ...mapActions([GET_COURSE_GROUP_POINTS_STATISTICS]),
    async getGradesStatistics() {
      if (this.group) {
        this.statistics = await this[GET_COURSE_GROUP_POINTS_STATISTICS]({
          group_number: this.group,
        });
        this.chartData = this.generateChartData();
      }
    },
  },
  watch: {
    async group() {
      await this.getGradesStatistics();
    },
    async start_date() {
      await this.getGradesStatistics();
    },
    async end_date() {
      await this.getGradesStatistics();
    },
  },
};
