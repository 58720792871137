<script>
import { defineComponent } from "vue";
import moment from "moment";
import 'moment/locale/ru';
import StatisticsGreen from "@/assets/icons/StatisticsGreen";
import StatisticsRed from "@/assets/icons/StatisticsRed";
import StatisticsGrey from "@/assets/icons/StatisticsGrey";
import TheTableHead from "@/components/TheTable/TheTableHead.vue";
import TheTableRow from "@/components/TheTable/TheTableRow.vue";
import TheTableBody from "@/components/TheTable/TheTableBody.vue";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";

export default defineComponent({
  name: "AttendanceTable",
  props:{
    tableData: {
      type: Array,
      required: true,
    }
  },
  components: {
    TheTableRowCell,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableHead,
    StatisticsGreen,
    StatisticsRed,
    StatisticsGrey,
  },
  methods: {
    formatToDayAndMonth(date) {
      moment.locale('ru');
      return moment(date).format('D MMM').toUpperCase();
    },
    attendanctToPercentage(attendance) {
      if(!attendance || typeof attendance !== "object") return '0%';

      const attendanceValues = Object.values(attendance);
      const total = attendanceValues.filter(status => status !== "unmarked").length;

      if (total === 0) return '0%';

      const attended = attendanceValues.filter(status => status === 'present').length;
      const percentage = (attended / total)*100;
      return `${percentage.toFixed(0)}%`;
    }
  }
});
</script>

<template>
  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-4">
      <div class="forStatisticsAttendance" v-if="tableData?.dates&&tableData?.students">
        <table class="min-w-full shadow divide-y divide-gray-300">
          <TheTableHead>
            <TheTableHeadCell>
              <div class="flex flex-col px-2 py-1">
                <div
                    class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  №
                </div>
              </div>
            </TheTableHeadCell>
            <TheTableHeadCell style="min-width: 300px;">
              <div class="flex flex-col px-2 py-1">
                <div
                    class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  Имя
                </div>
              </div>
            </TheTableHeadCell>
            <TheTableHeadCell v-for="item of tableData.dates" :key="item">
              <div class="flex flex-col px-2 py-1">
                <div class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500">{{ formatToDayAndMonth(item) }}</div>
              </div>
            </TheTableHeadCell>
            <TheTableHeadCell>
              <div class="flex flex-col  px-2 py-1">
                <div
                    class="text-xs  leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  %
                </div>
              </div>
            </TheTableHeadCell>
          </TheTableHead>
          <TheTableBody>
            <template v-for="(st, index) of tableData.students" :key="index">
              <TheTableRow>
                <TheTableRowCell>
                  {{index+1}}
                </TheTableRowCell>
                <TheTableRowCell>
                  <div class="flex gap-4 justify-start">
                    <div class="pl-4">
                      <img
                          :src="st.avatar"
                          alt="Avatar"
                          class="avatar"
                          v-if="st.avatar"
                      />
                      <div v-else>
                        <div class="img-placeholder">
                          {{ st?.name ? st?.name[0] : "." }}
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <div
                          class="text-sm font-medium tracking-wider text-gray-900"
                      >
                        {{ st.name }}
                      </div>
                      <div class="">Группа ...</div>
                    </div>
                  </div>
                </TheTableRowCell>
                <TheTableRowCell v-for="item of st.attendance" v-bind:key="item">
                  <StatisticsGreen v-if="item === 'present'" />
                  <StatisticsRed v-if="item === 'absent'" />
                  <StatisticsGrey v-if="item === 'unmarked'" />
                </TheTableRowCell>
                <TheTableRowCell >
                  {{attendanctToPercentage(st.attendance)}}
                </TheTableRowCell>
              </TheTableRow>

            </template>
          </TheTableBody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped>
.img-placeholder {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
  border: 1px solid #6b7280;
}
</style>
