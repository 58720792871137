<script>
import { defineComponent } from "vue";
import TheTable from "@/components/TheTable/TheTable.vue";
import TheTableHead from "@/components/TheTable/TheTableHead.vue";
import TheTableRow from "@/components/TheTable/TheTableRow.vue";
import TheTableBody from "@/components/TheTable/TheTableBody.vue";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
import AvatarPlaceholder from "@/views/courses/CourseEdit/Attendance/AvatarPlaceholder.vue";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";

export default defineComponent({
  name: "OverallStatistics",
  components: {
    TheTableRowCell,
    AvatarPlaceholder,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableHead,
    TheTable,
  },
  data() {
    return {
      students: [
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          status: true,
          last_active: "1 мая 2022, 21:16",
        },
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          status: true,
          last_active: "1 мая 2022, 21:16",
        },
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          status: true,
          last_active: "1 мая 2022, 21:16",
        },
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          status: false,
          last_active: "1 мая 2022, 21:16",
        },
      ],
    };
  },
});
</script>

<template>
  <div class="flex flex-col gap-10">
    <div class="flex gap-4">
      <div class="task__box flex flex-col gap-1 p-4 flex-1">
        <div class="task__box-title">Общее кол-во заданий</div>
        <div class="task__box-text">15</div>
      </div>
      <div class="task__box flex flex-col gap-1 p-4 flex-1">
        <div class="task__box-title">Домашние задания</div>
        <div class="task__box-text">10</div>
      </div>
      <div class="task__box flex flex-col gap-1 p-4 flex-1">
        <div class="task__box-title">Тесты</div>
        <div class="task__box-text">2</div>
      </div>
      <div class="task__box flex flex-col gap-1 p-4 flex-1">
        <div class="task__box-title">Индивидуальные работы</div>
        <div class="task__box-text">3</div>
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div class="statistics-box__title">Активные студенты</div>
      <div>
        <TheTable>
          <TheTableHead>
            <TheTableHeadCell>
              <div class="flex  px-2 py-1">
                <div
                    class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  №
                </div>
              </div>
            </TheTableHeadCell>
            <TheTableHeadCell>
              <div class="flex flex-col px-2 py-1">
                <div
                  class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  Имя
                </div>
              </div>
            </TheTableHeadCell>
            <TheTableHeadCell>
              <div class="flex flex-col px-2 py-1">
                <div
                  class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  Статус
                </div>
              </div>
            </TheTableHeadCell>
            <TheTableHeadCell>
              <div class="flex flex-col px-2 py-1">
                <div
                  class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  Последняя активность
                </div>
              </div>
            </TheTableHeadCell>
          </TheTableHead>
          <TheTableBody>
            <template v-for="(st, index) of students" :key="index">
              <TheTableRow>
                <TheTableRowCell>
                  {{index+1}}
                </TheTableRowCell>
                <TheTableRowCell>
                  <div class="flex gap-4 justify-start">
                    <div class="pl-4">
                      <img
                        :src="st.avatar"
                        alt="Avatar"
                        class="avatar"
                        v-if="st.avatar"
                      />
                      <div v-else>
                        <AvatarPlaceholder :student="st" />
                      </div>
                    </div>
                    <div class="flex flex-col">
                      <div
                        class="text-sm font-medium tracking-wider text-gray-900"
                      >
                        {{ st.first_name + " " + st.last_name }}
                      </div>
                      <div class="">{{ st.email }}</div>
                    </div>
                  </div>
                </TheTableRowCell>
                <TheTableRowCell
                  ><div
                    :class="st.status ? 'active_student' : 'not_active_student'"
                  >
                    {{ st.status ? "Активный" : "Не активный" }}
                  </div>
                </TheTableRowCell>
                <TheTableRowCell
                  ><div class="text-sm font-normal text-gray-500">
                    {{ st.last_active }}
                  </div>
                </TheTableRowCell>
              </TheTableRow>
            </template>
          </TheTableBody>
        </TheTable>
      </div>
    </div>
  </div>
</template>

<style scoped>
.task__box {
  background: #f9fafb;
}
.task__box-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6b7280;
}
.task__box-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}
.statistics-box__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}

.active_student {
  background: #d1fae5;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #065f46;
  border-radius: 10px;
  padding: 2px 10px;
  width: fit-content;
}
.not_active_student {
  background: #fef2f2;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #dc2626;
  border-radius: 10px;
  padding: 2px 10px;
  width: fit-content;
}
</style>
