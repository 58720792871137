<script>
import { computed, defineComponent, ref } from "vue";
import TheButton from "@/components/Button/Button.vue";
import LectureItem from "./LectureItem.vue";

import img18Dec from "@/assets/images/guests/18-12-24.png";
import img10Dec from "@/assets/images/guests/10-12-24.png";
import img29Nov from "@/assets/images/guests/29.11.24.png";
import img27Nov from "@/assets/images/guests/27-11-24.png";
import img18Nov from "@/assets/images/guests/18-11-24.png";
import img5Nov from "@/assets/images/guests/5-11-24.png";
import img29Oct from "@/assets/images/guests/29-10-24.png";
import img22Oct from "@/assets/images/guests/22-10-24.png";
import img11Oct from "@/assets/images/guests/11-10-24.png";

export default defineComponent({
  components: { TheButton, LectureItem },
  setup() {
    const isUpcoming = ref(true);

    const lectures = ref([
      { date: "18 декабря", speaker: "Айсана Джандигулова", description: "Фрилансер, WEB & UX/UI Дизайнер", badge: "UI/UX design", image: img18Dec, isUpcoming: false },
      { date: "10 декабря", speaker: "Айбар Усенов", description: "Head of Digital Transformation, Kazphosphate", badge: "Project Management", image: img10Dec, isUpcoming: false },
      { date: "29 ноября", speaker: "Merey Balgabayev", description: "Senior Big Data Project Manager", badge: "Python с ИИ", image: img29Nov, isUpcoming: false },
      { date: "27 ноября", speaker: "Мирра Котовская", description: "Иллюстратор, основатель школы Mirrart School", badge: "UI/UX design", image: img27Nov, isUpcoming: false },
      { date: "18 ноября", speaker: "Ulzhan Bissarinova", description: "Аналитик данных и продуктовый менеджер Soyle в ISSAI", badge: "Python с ИИ", image: img18Nov, isUpcoming: false },
      { date: "5 ноября", speaker: "Islam Iskezhanov", description: "BI Engineer at DataArt", badge: "Data analysis", image: img5Nov, isUpcoming: false },
      { date: "29 октября", speaker: "Журтанов Алмас", description: "Оператор Red Team(этичный хакер) в Deloitte Netherlands", badge: "Cybersecurity", image: img29Oct, isUpcoming: false },
      { date: "22 октября", speaker: "Сарсембинов Ануар", description: "Основатель и генеральный директор Smart System Technologies", badge: "Project management", image: img22Oct, isUpcoming: false },
      { date: "11 октября", speaker: "Sarsembinova (Gabbas) Malika", description: "Product designer в Blockchair и 3xpl", badge: "UI/UX design", image: img11Oct, isUpcoming: false },
    ]);

    const filteredLectures = computed(() => lectures.value.filter(lecture => lecture.isUpcoming === isUpcoming.value));

    return { isUpcoming, filteredLectures };
  }
});
</script>ы


<template>
  <div class="guest-lectures">
    <div class="guest-lectures__main">
      <div class="guest-lectures__nav">
        <h1 class="guest-lectures__title">Все гостевые лекции</h1>
        <div class="guest-lectures__btns">
          <TheButton
              class="guest-lecture__btn"
              :class="{ active: isUpcoming }"
              @click="isUpcoming = true"
          >
            Предстоящие
          </TheButton>
          <TheButton
              class="guest-lecture__btn"
              :class="{ active: !isUpcoming }"
              @click="isUpcoming = false"
          >
            Прошедшие
          </TheButton>
        </div>
      </div>
      <div class="lectures-list">
        <template v-if="filteredLectures.length === 0">
          <p class="no-lectures-message">
            <span>Уважаемые коллеги и студенты!</span>
            <br>
            <br>
            На данный момент в расписании не запланированы гостевые лекции. Мы сообщим о новых мероприятиях, как только появится информация о предстоящих лекциях.
            <br>
            <br>
            Следите за обновлениями!
          </p>
        </template>
        <template v-else>
          <template v-for="(lecture, index) in filteredLectures" :key="index">
            <LectureItem
                :date="lecture.date"
                :speaker="lecture.speaker"
                :description="lecture.description"
                :badge="lecture.badge"
                :image="lecture.image"
            />
            <hr v-if="index < filteredLectures.length - 1" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>


<style scoped>
.no-lectures-message {
  font-size: 18px;
  color: #9ca3af;
  margin-top: 40px;
  font-weight: 500;
}

.no-lectures-message > span {
  font-size: 20px;
  font-weight: 700;
}

.guest-lectures__title {
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
}
.guest-lectures {
  background-color: #2b2e3b;
  display: flex;
  padding: 64px 0;
  min-height: 100vh;

}
.guest-lectures__main {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
}
.guest-lectures__btns {
  margin-top: 32px;
  display: flex;
  gap: 16px;
}
.guest-lecture__btn {
  padding: 6px 16px;
  background-color: #3d3f48;
  border-color: #786ce6b2;
  transition: all 0.3s ease;
}
.guest-lecture__btn.active {
  background-color: #786CE6;
}
.lectures-list {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.lectures-list > hr {
  border: none;
  height: 1px;
  background-color: #9ca3af;
  opacity: 0.15;
  width: 100%;
}

@media (max-width: 1100px) {
  .guest-lectures__main {
    width: 90%;
  }

  .lectures-list{
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .guest-lectures__nav{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .guest-lectures__main {
    width: 100%;
  }

  .lectures-list > hr {
    width: 90%;
  }

  .guest-lectures__title {
    font-size: 30px;
  }

  .lectures-list{
    width: 90%;
    margin: 50px 40px;
  }
}
</style>
