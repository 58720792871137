import { mapActions } from "vuex";
import {
  CREATE_MANY_COURSE_GROUP_POINTS,
  DOWNLOAD_GRADES,
  GET_COURSE_GROUP_POINTS,
} from "@/store/student-grades";
import { FinalGradeSelector } from "@/components/Selectors/GradeSelector.vue";

export default {
  data() {
    return {
      students: {},
      grade_categories: [],
      grades: [],
      position: false,
    };
  },
  computed: {
    hasStudents() {
      return Object.keys(this.students).length > 0;
    },
  },
  methods: {
    ...mapActions([
      GET_COURSE_GROUP_POINTS,
      CREATE_MANY_COURSE_GROUP_POINTS,
      DOWNLOAD_GRADES,
    ]),
    async downloadGrades(event) {
      event.stopPropagation();
      const data = await this[DOWNLOAD_GRADES]({
        course: this.course_id,
        group_number: this.group_id,
        task_category_ids: this.categories
          .map((category) => category.id)
          .join(","),
        user: this.search,
        total_lte:
          this.gradeSelector === FinalGradeSelector.All
            ? ""
            : this.gradeSelector === FinalGradeSelector.Mid
            ? "70"
            : "50",
      });

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "grades.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    async getStudentGrades() {
      if (this.group_id) {
        const data = await this.$store.dispatch(GET_COURSE_GROUP_POINTS, {
          course: this.course_id,
          group_number: this.group_id,
          task_category_ids: this.categories
            .map((category) => category.id)
            .join(","),
          user: this.search,
          total_lte:
            this.gradeSelector === FinalGradeSelector.All
              ? ""
              : this.gradeSelector === FinalGradeSelector.Mid
              ? "70"
              : "50",
        });
        this.fetched = true;

        this.grade_categories = data?.resources ?? [];
        this.students = data?.users?.filter(user => user.groups[0]==="student") ?? [];
        this.grades = data?.points ?? [];
      }
    },
    async createCoursePoints() {
      this.loading = "Сохраняем...";
      const obj = this.grades;
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          delete obj[key]["total"];
        }
      }

      await this.$store.dispatch(CREATE_MANY_COURSE_GROUP_POINTS, {
        group_number: this.group_id,
        points: this.grades,
      });
      await this.getStudentGrades();
      this.loading = "Сохранено";
    },
  },

  watch: {
    async group_id(newVal) {
      if (newVal !== null) {
        await this.getStudentGrades();
      }
    },
    async gradeSelector(newVal) {
      if (newVal !== null) {
        await this.getStudentGrades();
      }
    },
    async search(newVal) {
      if (newVal !== null) {
        await this.getStudentGrades();
      }
    },
    async categories(newVal) {
      if (newVal !== null) {
        await this.getStudentGrades();
      }
    },
  },
};
