<template>
  <div class="site-container">
    <div class="banner-header__img top-0">
      <div
        class="flex-1 flex gap-6 banner-header items-center justify-center py-6"
      >
        <div class="banner-header__title">
          Получите частичный <span class="banner-header__text">грант</span> с покрытием от 30% до 70% обучения в новом потоке и пройдите бесплатный интенсив по IT
        </div>
        <a href="https://grants.alphaedu.tech/">
          <TheButton class="banner-header__button">
            Подробнее
          </TheButton>
        </a>
      </div>
    </div>

    <TheLandingHeader @scrollToFooter="handleScrollToFooter" />
    <div class="content-container"><router-view></router-view></div>
    <TheNewLandingFooter ref="footerRef" />
  </div>
</template>

<script>
import TheNewLandingFooter from "@/views/core/Landing/NewLanding/TheNewLandingFooter.vue";
import TheLandingHeader from "./TheLandingHeader.vue";
import TheButton from "@/components/Button/Button.vue";
export default {
  components: {
    TheButton,
    TheNewLandingFooter,
    TheLandingHeader,
  },
  methods: {
    handleScrollToFooter() {
      this.$nextTick(() => {
        const footerElement = this.$refs.footerRef.$el;

        footerElement.scrollIntoView({ behavior: "smooth" });
      });
    },
  },
};
</script>

<style scoped>
.site-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-container {
  flex-grow: 1;
}
.banner-header__img {
  background-image: url("@/assets/images/landing/header-banner.png");
  min-height: 60px;
}
.banner-header__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 36.8px;
  text-align: left;
  color: white;
}

.banner-header__text{
  color: #A599F5;
}
.banner-header__button {
  padding: 16px 25px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 25px;
}

.banner-header{
  padding-left: 24px;
  padding-right: 24px;
}

.banner-header__gradient {
  background: linear-gradient(to bottom, #5477bd 49.33%, #7251a1 68.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 650px) {
  .banner-header{
    padding: 24px;
    flex-direction: column;
    align-items: start;
  }

  .banner-header__title{
    font-size: 20px;
    line-height: 22px;
  }

  .banner-header__button{
    font-size: 16px;
    padding: 12px 25px;
  }
}
</style>
