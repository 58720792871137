<template>
  <svg
    width="9"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.08345 7.91471C8.00718 7.83751 7.91635 7.77622 7.81621 7.73439C7.71608 7.69255 7.60864 7.67101 7.50012 7.67101C7.3916 7.67101 7.28415 7.69255 7.18402 7.73439C7.08389 7.77622 6.99305 7.83751 6.91678 7.91471L5.33345 9.49805V1.83138C5.33345 1.37305 4.95845 0.998047 4.50012 0.998047C4.04178 0.998047 3.66678 1.37305 3.66678 1.83138L3.66678 9.49805L2.08345 7.91471C2.00718 7.83751 1.91635 7.77622 1.81621 7.73439C1.71608 7.69255 1.60864 7.67101 1.50012 7.67101C1.39159 7.67101 1.28415 7.69255 1.18402 7.73439C1.08389 7.77622 0.993052 7.83751 0.916783 7.91471C0.591784 8.23971 0.591784 8.75638 0.916783 9.08138L3.90845 12.073C3.98554 12.1503 4.07712 12.2116 4.17793 12.2534C4.27874 12.2952 4.38681 12.3168 4.49595 12.3168C4.60509 12.3168 4.71316 12.2952 4.81397 12.2534C4.91478 12.2116 5.00635 12.1503 5.08345 12.073L8.08345 9.08138C8.16065 9.00511 8.22194 8.91428 8.26377 8.81414C8.30561 8.71401 8.32715 8.60657 8.32715 8.49805C8.32715 8.38953 8.30561 8.28208 8.26377 8.18195C8.22194 8.08182 8.16065 7.99098 8.08345 7.91471Z"
      fill="black"
    />
  </svg>
</template>
<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
