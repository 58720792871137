<template>
  <router-link
    custom
    :to="`/courses/${id}/${destination}`"
    v-slot="{ navigate, href }"
  >
    <a
      :href="href"
      @click="navigate"
      class="shadow-lg rounded-xl bg-white w-full h-fit overflow-hidden"
      style="max-width: 264px"
    >
      <div
        class="h-40 hidden sm:flex bg-no-repeat bg-cover"
        :style="`background-image:url(${logo})`"
      />
      <div class="p-4 flex flex-col justify-between h-[12.4rem] ">
        <span>
          <CourseCardSpecializations :specializations="specializations" />
          <p
            class="text-gray-900 text-lg font-semibold hover:text-clip line-clamp-4 break-word leading-6 "
          >
            {{ name }}
          </p>
        </span>
        <span class="flex items-center mt-3">
         <div class="flex flex-col gap-1 h-20 w-full overflow-y-auto ">

            <div
                v-for="(instructor, index) in instructors"
                :key="`instructor_${index}`"
                class="flex items-center gap-2 space-x-3 "
            >
              <UserAvatar
                  :length="instructors.length"
                  :url="formatUrl(instructor.instructor.avatar)"
                  :index="index"
                  class="flex-shrink-0"
              />
              <div>
                <p class="text-gray-900 text-sm font-medium">
                  {{ instructor.instructor.full_name }}
                </p>
                <span>
                  <p class="text-gray-500 text-sm font-medium">
                    <span v-if="start_date && end_date">
                      {{ formattedStartDate }} - {{ formattedEndDate }}
                    </span>
                    {{ totalHours === "0" ? "" : "· " + totalHours + " ч" }}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </span>
      </div>
    </a>
  </router-link>
</template>
<script>
import CourseCardMixin from "@/components/Courses/Card/CourseCardMixin";
import CourseCardSpecializations from "@/components/Courses/Card/CourseCardSpecializations";
import UserAvatar from "@/components/UserAvatar";

export default {
  components: { UserAvatar, CourseCardSpecializations },
  mixins: [CourseCardMixin],
  props: {
    instructors: [],
    destination: String,
  },
  methods: {
    formatUrl(url) {
      if (url !== null) {
        return url.replace("http://", "https://");
      }
    },
  },
};
</script>

