<script>
import { defineComponent } from "vue";
import TheTable from "@/components/TheTable/TheTable.vue";
import TheTableHead from "@/components/TheTable/TheTableHead.vue";
import TheTableRow from "@/components/TheTable/TheTableRow.vue";
import TheTableBody from "@/components/TheTable/TheTableBody.vue";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
import AvatarPlaceholder from "@/views/courses/CourseEdit/Attendance/AvatarPlaceholder.vue";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default defineComponent({
  name: "ProgressStatistics",
  components: {
    ProgressBar,
    TheTableRowCell,
    AvatarPlaceholder,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableHead,
    TheTable,
  },
  data() {
    return {
      students: [
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          progress: 10,
        },
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          progress: 15,
        },
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          progress: 56,
        },
        {
          first_name: "Айман",
          last_name: "Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          progress: 87,
        },
      ],
    };
  },
});
</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="statistics-box__title">Процент завершенных модулей</div>
    <div>
      <TheTable>
        <TheTableHead>
          <TheTableHeadCell>
            <div class="flex flex-col px-2 py-1">
              <div
                  class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
              >
                №
              </div>
            </div>
          </TheTableHeadCell>
          <TheTableHeadCell>
            <div class="flex flex-col px-2 py-1">
              <div
                class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
              >
                Имя
              </div>
            </div>
          </TheTableHeadCell>

          <TheTableHeadCell>
            <div class="flex flex-col px-2 py-1">
              <div
                class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
              >
                Прогресс
              </div>
            </div>
          </TheTableHeadCell>
        </TheTableHead>
        <TheTableBody>
          <template v-for="(st, index) of students" :key="index">
            <TheTableRow>
              <TheTableRowCell>
                <div class="flex gap-4 justify-start">
                  <div class="pl-4">
                    {{index+1}}
                  </div>
                </div>
              </TheTableRowCell>
              <TheTableRowCell>
                <div class="flex gap-4 justify-start">
                  <div class="pl-4">
                    <img
                      :src="st.avatar"
                      alt="Avatar"
                      class="avatar"
                      v-if="st.avatar"
                    />
                    <div v-else>
                      <AvatarPlaceholder :student="st" />
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <div
                      class="text-sm font-medium tracking-wider text-gray-900"
                    >
                      {{ st.first_name + " " + st.last_name }}
                    </div>
                    <div class="">{{ st.email }}</div>
                  </div>
                </div>
              </TheTableRowCell>
              <TheTableRowCell>
                <div class="flex">
                  <ProgressBar
                    :progress="st.progress"
                    style="width: 150px"
                    class="mt-2 mr-2"
                  />
                  <span>{{ st.progress }}%</span>
                </div>
              </TheTableRowCell>
            </TheTableRow>
          </template>
        </TheTableBody>
      </TheTable>
    </div>
  </div>
</template>

<style scoped>
.statistics-box__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}
</style>
