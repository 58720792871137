import { mapActions } from "vuex";
import { GET_MY_COURSE_GROUP_POINTS } from "@/store/student-grades";
import { GET_MY_COURSE_GROUPS } from "@/store/student-users";

export default {
  data() {
    return {
      course_id: null,
      group_id: null,
      grade_categories: [],
      grades: [],
      total_grades: 0,
    };
  },
  async mounted() {
    this.course_id = this.$route.params.id;
    const groups = await this[GET_MY_COURSE_GROUPS]();
    this.group_id = groups.find(
      (gr) => gr.course === parseInt(this.course_id)
    ).id;

    await this.getMyGrades();
  },
  methods: {
    ...mapActions([GET_MY_COURSE_GROUP_POINTS, GET_MY_COURSE_GROUPS]),

    async getMyGrades() {
      const data = await this[GET_MY_COURSE_GROUP_POINTS]({
        group_number: this.group_id,
      });

      this.grade_categories = data?.resources ?? [];
      this.grades = data?.points ?? [];

      this.grade_categories.forEach((resource) => {
        this.grades[resource.id] = data?.points?.[resource.id] ?? { grade: 0 };
      });
      this.total_grades= data?.points?.total_score ?? 0;
    },
  },
};
